import { PaddedContentContainer } from "@brusnika.tech/ui-portal";

import User from "@widgets/user/ui/User";

const UsersPage = () => {
  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <User />
    </PaddedContentContainer>
  );
};

export default UsersPage;
