import {
  AppBar,
  defaultProjectServicesOptions,
  ITab,
  Layout,
  ProjectSelectOptionsType,
  UserProfileBar
} from "@brusnika.tech/ui-portal";
import { Stack } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import cn from "classnames";
import menuConfig from "public/i18n/menu.json";
import { Outlet, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { IUser } from "@skm-shared/user";

import { useConfig } from "@app/index";
import { useRootStore } from "@app/root-store/root.store";
import JiraIssue from "@features/jira/ui/JiraIssue";
import LanguageSelect from "@features/language-select/LanguageSelect";
import { QueryParams } from "@shared/consts";
import SidebarSearch from "@widgets/sidebars/ui/SidebarSearch";

interface Props {
  navigationTree: ITab;
  user?: IUser;
}

const menuConfigTyped = menuConfig as {
  ru: ProjectSelectOptionsType;
  en: ProjectSelectOptionsType;
};

const Root = ({ navigationTree, user }: Props) => {
  const tolgee = useTolgee();
  const currentLanguage = tolgee.getLanguage() === "en" ? "en" : "ru";

  const featureConfig = useConfig();

  const [searchParams] = useSearchParams();
  const rootStore = useRootStore(useShallow(state => state));

  if (searchParams.get(QueryParams.onlyFrame)) {
    return <Outlet />;
  }

  return (
    <>
      <JiraIssue user={user ?? ({} as IUser)} />

      <Layout
        appBar={
          <AppBar
            className={cn({
              "g-fullscreen-header_hidden": rootStore.isFullscreen,
              "g-fullscreen-header": !rootStore.isFullscreen
            })}
            logo="B.ERP"
            name="СКМ"
            navigationTree={navigationTree}
            projectSelectOptions={menuConfigTyped[currentLanguage] ?? defaultProjectServicesOptions}
            userAppBar={
              <Stack flexDirection="row" gap={2}>
                <SidebarSearch />
                {featureConfig.config?.language === "en" && <LanguageSelect />}
              </Stack>
            }
            userProfile={<UserProfileBar isCollapsed avatarAlt={user?.name} topText={user?.email} />}
          />
        }
      >
        <Outlet />
      </Layout>
    </>
  );
};

export default Root;
