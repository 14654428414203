import { FC, useEffect } from "react";

import { Spinner } from "@brusnika.tech/ui-kit";
import { ITab, useLayout } from "@brusnika.tech/ui-portal";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";

import Root from "@app/Root";
import { useInitialApp } from "@app/hooks/useInitialApp";
import { useGetCameraTree } from "@entities/camera/api/camera.service";
import { getCameraRoutes } from "@entities/camera/lib/get-camera-routes";
import { useGetMonitorTree } from "@entities/monitor/api/tree.service";
import { getMonitorRoutes } from "@entities/monitor/lib/get-monitor-routes";
import { useMonitorTreeStore } from "@entities/monitor/model/monitor-tree.store";
import AdminMainPage from "@pages/AdminMain.page";
import AdminTreePage from "@pages/AdminTree.page";
import CameraPage from "@pages/Camera.page";
import MainPage from "@pages/Main.page";
import RolesPage from "@pages/Roles.page";
import UpdateMonitorPage from "@pages/UpdateMonitor.page";
import UserVisitPage from "@pages/UserVisit.page";
import UsersPage from "@pages/Users.page";
import { AdminRoutes, TopRoutes } from "@shared/consts";
import Layout from "@widgets/layout/ui/Layout";
import AdminMonitorsSidebar from "@widgets/sidebars/ui/AdminMonitorsSidebar";
import AdminSidebar from "@widgets/sidebars/ui/AdminSidebar";
import CameraSidebar from "@widgets/sidebars/ui/CameraSidebar";
import MonitorSidebar from "@widgets/sidebars/ui/MonitorSidebar";

const Routing: FC = () => {
  const { user, config, isLoading } = useInitialApp();
  const { data: monitorTreeData, isLoading: isLoadingMonitors } = useGetMonitorTree();
  const { data: cameraTreeData, isLoading: isLoadingCameras } = useGetCameraTree({
    disabled: !config?.CAMERAS_ENABLED
  });
  const setIsAdmin = useMonitorTreeStore(state => state.setIsAdmin);
  const { setOpenedLeftDrawer } = useLayout();
  const { t } = useTranslate("main");
  const ADMIN_ROLE_LEVEL = 4;

  const topTabs: ITab[] = [
    { id: "Главная", path: TopRoutes.MAIN, title: t("routing.topTab-main", "Главная"), children: [] }
  ];

  if (config?.CAMERAS_ENABLED) {
    topTabs.push({ id: "Камеры", path: TopRoutes.CAMERAS, title: t("routing.topTab-cameras", "Камеры"), children: [] });
  }

  if (user?.roles?.some(r => r.level >= ADMIN_ROLE_LEVEL)) {
    topTabs.push(
      {
        id: "Управление мониторами",
        path: TopRoutes.ADMIN_MONITOR_TREE,
        title: t("routing.topTab-adminMonitors", "Управление мониторами"),
        children: []
      },
      {
        id: "Администрирование",
        path: TopRoutes.ADMIN_MAIN,
        title: t("routing.topTab-admin", "Администрирование"),
        children: []
      }
      // TODO: Сделать по требованию
      // { id: "Управление камерами", path: TopRoutes.ADMIN_CAMERA_TREE, title: "Управление камерами", children: [] }
    );
    setIsAdmin(true);
  }

  const navigationTree: ITab = {
    id: "",
    path: "",
    title: "",
    children: topTabs
  };

  useEffect(() => {
    setOpenedLeftDrawer(true);
  }, [setOpenedLeftDrawer]);

  if (isLoading || isLoadingMonitors || isLoadingCameras) {
    return (
      <Stack alignItems="center" height="95vh" justifyContent="center" width="95vw">
        <Spinner size="large" text="Подготавливаем мониторы" />
      </Stack>
    );
  }

  const adminRoutes: RouteObject[] = [
    { index: true, element: <AdminMainPage /> },
    { path: AdminRoutes.ROLES, element: <RolesPage /> },
    { path: AdminRoutes.USERS, element: <UsersPage /> },
    { path: AdminRoutes.VISITS, element: <UserVisitPage /> }
  ];

  if (config?.ERP_ENABLED) {
    adminRoutes.push({ path: AdminRoutes.UPDATE_MONITOR, element: <UpdateMonitorPage /> });
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root navigationTree={navigationTree} user={user} />,
      children: [
        {
          path: TopRoutes.ADMIN_MONITOR_TREE,
          element: <Layout sidebar={() => <AdminMonitorsSidebar />} sidebarName="admin-monitor-tree" />,
          children: [{ index: true, element: <AdminTreePage /> }]
        },
        {
          path: TopRoutes.ADMIN_CAMERA_TREE,
          element: <Layout sidebar={() => <div></div>} sidebarName="admin-camera-tree" />,
          children: []
        },
        {
          path: TopRoutes.ADMIN_MAIN,
          element: <Layout sidebar={() => <AdminSidebar />} sidebarName="admin-main" />,
          children: adminRoutes
        },
        {
          path: TopRoutes.MAIN,
          element: <Layout sidebar={() => <MonitorSidebar />} sidebarName="main" />,
          children: getMonitorRoutes(monitorTreeData?.[0]).concat({ index: true, element: <MainPage /> })
        },
        {
          path: TopRoutes.CAMERAS,
          element: <Layout sidebar={() => <CameraSidebar />} sidebarName="cameras" />,
          children: getCameraRoutes(cameraTreeData?.[0]).concat({ index: true, element: <CameraPage /> })
        }
      ]
    },
    {
      path: "*",
      element: <Navigate to={TopRoutes.MAIN} />
    }
  ]);

  return <RouterProvider router={router} />;
};

export default Routing;
