import { FC, useEffect, useMemo } from "react";

import { IconAdd, IconBlock, IconFolders, IconSave, IconTrash } from "@brusnika.tech/ui-icons";
import { Button } from "@brusnika.tech/ui-kit";
import { useLayout } from "@brusnika.tech/ui-portal";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { INode, TreeContentType } from "packages/shared/src/monitors";
import { FormProvider, useForm } from "react-hook-form";

import { IRole } from "@skm-shared/user";

import { useConfigStore } from "@entities/config/model/config.store";
import { MONITOR_PAGES } from "@entities/monitor/lib/available-component";
import FormExtraInfo from "@features/manage-tree-form/ui/FormExtraInfo";
import FormMainInfo from "@features/manage-tree-form/ui/FormMainInfo";
import { getSelectElement } from "@shared/lib/utils";

import { checkImport, manageTreeFormMapper } from "../lib";
import { IManageTreeForm } from "../types";

interface Props {
  node: INode;
  parentNode?: INode;
  roles: IRole[];
  onSubmit: (form: IManageTreeForm) => void;
  onRemove: (node: INode) => void;
  onAdd: (node: INode) => void;
}

const ManageTreeForm: FC<Props> = ({ node, parentNode, onSubmit, onRemove, onAdd, roles }) => {
  const { isDesktop } = useLayout();
  const { t } = useTranslate("main");
  const config = useConfigStore(state => state.config);
  const parentOption = useMemo(
    () => (parentNode ? getSelectElement(parentNode.id, parentNode.title) : undefined),
    [parentNode]
  );

  const formMethods = useForm<IManageTreeForm>({
    defaultValues: {
      roles: [],
      parent: null,
      componentName: null,
      contentStatus: node.contentStatus,
      contentType: node.contentType,
      hasDivider: node.hasDivider,
      isDefaultRoute: node.isDefaultRoute,
      host: { label: config.SUPERSET_URL, src: config.SUPERSET_URL }
    },
    values: manageTreeFormMapper(node, parentOption),
    mode: "onChange"
  });
  const contentType = formMethods.watch("contentType");

  const onReset = () => {
    formMethods.reset({
      ...node,
      componentName: undefined,
      parent: parentOption,
      roles: [],
      host: null
    });
  };

  useEffect(() => {
    if (contentType === TreeContentType.IFRAME) {
      formMethods.setValue("componentName", { label: MONITOR_PAGES.IFRAME, src: MONITOR_PAGES.IFRAME });
    }
  }, [contentType, formMethods]);

  return (
    <Stack gap={5} height="100%" justifyContent="space-between">
      <Stack direction="column" gap={5}>
        <FormProvider {...formMethods}>
          <FormMainInfo parentNode={parentNode} parentOption={parentOption} />

          <FormExtraInfo parentNode={parentNode} roles={roles} />
        </FormProvider>
      </Stack>

      <Stack direction={isDesktop ? "row" : "column"} gap={5} justifyContent="space-between" p={5} sx={{ width: 250 }}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button startIcon={<IconSave />} variant="filled" onClick={formMethods.handleSubmit(onSubmit)}>
          {t("tForm.btn-save", "Сохранить")}
        </Button>

        <Button startIcon={<IconAdd />} variant="filled" onClick={() => onAdd(node)}>
          {t("tForm.btn-addChild", "Добавить ребенка")}
        </Button>

        <Button startIcon={<IconTrash />} variant="filled" onClick={() => onRemove(node)}>
          {t("tForm.btn-remove", "Удалить")}
        </Button>

        <Button startIcon={<IconBlock />} variant="filled" onClick={onReset}>
          {t("tForm.btn-reset", "Сбросить")}
        </Button>

        <Button
          startIcon={<IconFolders />}
          variant="filled"
          onClick={() => checkImport(formMethods.getValues("componentName")?.src)}
        >
          {t("tForm.btn-componentAvailability", "Проверить доступность компонента")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ManageTreeForm;
