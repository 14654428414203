import { PropsWithChildren, useEffect, useState } from "react";

import { ConfigState } from "@brusnika.tech/ui-config-provider";
import { Spinner } from "@brusnika.tech/ui-kit";
import { Stack } from "@mui/material";
import { DevTools, FormatSimple, Tolgee, TolgeeInstance, TolgeeProvider } from "@tolgee/react";

import { ConfigType } from "@app/config.schema";

interface Props extends PropsWithChildren {
  useConfig: () => ConfigState<ConfigType>;
}
export const BrusnikaTolgeeProvider = ({ children, useConfig }: Props) => {
  const { isLoaded, config } = useConfig();
  const [tolgee, setTolgee] = useState<TolgeeInstance | null>(null);

  useEffect(() => {
    if (isLoaded && config) {
      const tolgeeInstance = Tolgee()
        .use(DevTools())
        .use(FormatSimple())
        .init({
          staticData: {
            "ru:main": () => import("public/i18n/main/ru.json"),
            "en:main": () => import("public/i18n/main/en.json")
          },
          language: config.language,
          availableLanguages: ["ru", "en"],
          ns: ["common"],
          tagNewKeys: ["draft"],
          ...(config.tolgeeApiUrl ? { apiUrl: config.tolgeeApiUrl, apiKey: config.tolgeeKey } : undefined)
        });

      setTolgee(tolgeeInstance);
    }
  }, [isLoaded, config]);

  const loader = () => (
    <Stack alignItems="center" height="95vh" justifyContent="center" width="95vw">
      <Spinner size="large" text="Инициализация перевода" />
    </Stack>
  );

  if (!tolgee) return loader();

  return (
    <TolgeeProvider fallback={loader()} tolgee={tolgee}>
      {children}
    </TolgeeProvider>
  );
};
