import { FC } from "react";

import { IconArrowLeft, IconArrowRight } from "@brusnika.tech/ui-icons";
import { Button, Select } from "@brusnika.tech/ui-kit";
import { Stack, TextField, Typography } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { useTranslate } from "@tolgee/react";

import { isOptionEqualToValue } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";

interface Props {
  table: Table<any>;
  handleChangeGlobalFilter: (value: string) => void;
}

const TableControls: FC<Props> = ({ table, handleChangeGlobalFilter }) => {
  const { t } = useTranslate("main");
  const paginationOptions: ISelectElement[] = [
    { src: "10", label: "10" },
    { src: "20", label: "20" },
    { src: "30", label: "30" },
    { src: "40", label: "40" },
    { src: "50", label: "50" },
    { src: "100", label: "100" }
  ];

  return (
    <Stack alignItems="center" direction="row" gap={4}>
      <Button
        disabled={!table.getCanPreviousPage()}
        startIcon={<IconArrowLeft iconSize="medium" />}
        sx={{ minWidth: 20 }}
        onClick={() => table.previousPage()}
      />

      <Button
        disabled={!table.getCanNextPage()}
        startIcon={<IconArrowRight iconSize="medium" />}
        sx={{ minWidth: 20 }}
        onClick={() => table.nextPage()}
      />

      <Select
        isOptionEqualToValue={isOptionEqualToValue}
        options={paginationOptions}
        value={{ src: `${table.getState().pagination.pageSize}`, label: `${table.getState().pagination.pageSize}` }}
        onChange={(_, value) => {
          table.setPageSize(Number(value?.src));
        }}
      />

      <Typography>
        {t("table.controls-page", "Страница")}: {table.getState().pagination.pageIndex + 1}{" "}
        {t("table.controls-from", "из")} {table.getPageCount()}
      </Typography>

      <Typography>
        {t("table.controls-total", "Всего")}: {table.getCoreRowModel().rows.length}
      </Typography>

      <Stack alignItems="center" direction="row" gap={2}>
        <Typography> {t("table.controls-search", "Поиск")}: </Typography>
        <TextField onChange={e => handleChangeGlobalFilter(e.target.value)} />
      </Stack>
    </Stack>
  );
};

export default TableControls;
