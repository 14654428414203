import { Select } from "@brusnika.tech/ui-kit";
import { useTolgee } from "@tolgee/react";

type LanguageSelectOption = { value: string; label: string };

const createOption = (value: string, label: string) => ({ value: value, label: label });

const languageOptions: LanguageSelectOption[] = [createOption("en", "En"), createOption("ru", "Ru")];

const LanguageSelect = () => {
  const tolgee = useTolgee();

  return (
    <Select<LanguageSelectOption>
      options={languageOptions}
      slotProps={{
        paper: {
          sx: {
            backgroundColor: "#5b5e5e",
            color: "#fff",
            ".MuiAutocomplete-option[aria-selected='true']": { backgroundColor: "#444646!important" }
          }
        }
      }}
      sx={{
        width: "55px",
        backgroundColor: "#5b5e5e",
        ".MuiInputBase-input": { color: "#fff!important" }
      }}
      value={languageOptions.find(option => option.value === tolgee.getLanguage())}
      onChange={(_, value) => {
        if (value) {
          void tolgee.changeLanguage(value.value);
        }
      }}
    />
  );
};

export default LanguageSelect;
