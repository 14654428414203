import { createStronglyTypedConfigContext } from "@brusnika.tech/ui-config-provider";
import { theme } from "@brusnika.tech/ui-kit";
import { DrawerProvider } from "@brusnika.tech/ui-portal";
import NiceModal from "@ebay/nice-modal-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useShallow } from "zustand/react/shallow";

import Routing from "@app/Routing";
import { configSchema, ConfigType, defaultConfig } from "@app/config.schema";
import { BrusnikaTolgeeProvider } from "@app/providers/TolgeeProvider";
import { useRootStore } from "@app/root-store/root.store";
import ErrorBoundary from "@entities/error-boundary/ui/ErrorBoundary";
import Fullscreen from "@shared/ui/fullscreen/Fullscreen";
import "./index.scss";

export const { ConfigProvider, useConfig } = createStronglyTypedConfigContext<ConfigType>();

export const App = () => {
  const rootStore = useRootStore(useShallow(state => state));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer limit={3} position="bottom-right" />

      <ErrorBoundary>
        <DrawerProvider defaultOpenedLeftDrawer defaultRightOverlaps>
          <NiceModal.Provider>
            <Fullscreen isEnabled={rootStore.isFullscreen} onChange={v => rootStore.setFullscreen(v)}>
              <ConfigProvider
                configSchema={configSchema}
                configUrl={import.meta.env.VITE_TOLGEE_CONFIG_URL}
                defaultConfig={defaultConfig}
                format="json"
              >
                <BrusnikaTolgeeProvider useConfig={useConfig}>
                  <Routing />
                </BrusnikaTolgeeProvider>
              </ConfigProvider>
            </Fullscreen>
          </NiceModal.Provider>
        </DrawerProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
