import { FC, useMemo } from "react";

import { IconTrash } from "@brusnika.tech/ui-icons";
import { IconButton, Spinner } from "@brusnika.tech/ui-kit";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { toast } from "react-toastify";

import { useGetRoles } from "@entities/role/api/role.service";
import { useGetUsers, useRemoveUser, useUpdateUser } from "@entities/user/api/user.service";
import { IUserUpdateForm } from "@entities/user/types";
import { formatDate, getSelectElement } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";
import Table from "@shared/ui/table/Table";
import { CellFormat, IColumn } from "@shared/ui/table/types";

const UserTable: FC = () => {
  const { data: users } = useGetUsers();
  const { data: roles = [] } = useGetRoles();
  const { mutateAsync: mutateUpdateUser } = useUpdateUser();
  const { mutateAsync: mutateRemoveUser } = useRemoveUser();
  const { t } = useTranslate("main");

  const tableData = useMemo(
    () =>
      users?.list.map(u => ({ ...u, roles: u.roles.map(r => getSelectElement(r.id, `${r.level} - ${r.name}`)) })) ?? [],
    [users]
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        header: t("user.table-email", "E-mail"),
        accessor: "email",
        isEditable: true
      },
      {
        header: t("user.table-name", "Имя"),
        accessor: "name",
        isEditable: true
      },
      {
        header: t("user.table-roles", "Роли"),
        accessor: "roles",
        format: ({ value }: CellFormat<ISelectElement[]>) => (
          <Stack>
            {value.map(v => (
              <Typography fontSize={11}>{v.label}</Typography>
            ))}
          </Stack>
        ),
        isEditable: true,
        meta: {
          options: roles.map(r => getSelectElement(r.id, `${r.level} - ${r.name}`))
        }
      },
      {
        header: t("user.table-dateCreated", "Дата создания"),
        accessor: "createdAt",
        format: ({ value }: CellFormat<Date>) => formatDate(value, "DD.MM.YYYY HH:mm")
      },
      {
        header: t("user.table-dateUpdated", "Дата обновления"),
        accessor: "updatedAt",
        format: ({ value }: CellFormat<Date | null>) => (value ? formatDate(value, "DD.MM.YYYY HH:mm") : "")
      },
      {
        header: t("user.table-actions", "Действия"),
        accessor: "actions",
        format: ({ row }: CellFormat<never, IUserUpdateForm>) => (
          <IconButton onClick={() => onRemoveSubmit(row.id)}>
            <IconTrash />
          </IconButton>
        )
      }
    ],
    [roles]
  );

  const onChange = (rows: Record<number, IUserUpdateForm | undefined>) => {
    for (const key in rows) {
      const row = rows[key];

      if (row) {
        void onEditSubmit(row);
      }
    }
  };

  const onEditSubmit = async (form: IUserUpdateForm) => {
    try {
      await mutateUpdateUser({
        id: form.id,
        email: form.email,
        name: form.name,
        createdAt: form.createdAt,
        updatedAt: form.updatedAt,
        roleIds: form.roles.map(r => r.src ?? "")
      });
    } catch (e) {
      toast.error("Ошибка обновления пользователя");
    }
  };

  const onRemoveSubmit = async (id: string) => {
    try {
      const isConfirmed = window.confirm("Вы уверены что хотите удалить пользователя?");
      if (!isConfirmed) return;
      await mutateRemoveUser(id);
    } catch (e) {
      toast.error("Ошибка удаления пользователя");
    }
  };

  if (!users?.list.length) {
    return <Spinner text="Загрузка пользователей" />;
  }

  return <Table<IUserUpdateForm> columns={columns} data={tableData} onChange={onChange} />;
};

export default UserTable;
