import { useCallback, useMemo, useState } from "react";

import { PaginationFull, Spinner, TableCell } from "@brusnika.tech/ui-kit";
import { Stack } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, PaginationState, useReactTable } from "@tanstack/react-table";
import { useTranslate } from "@tolgee/react";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { useGetVisits } from "@entities/user/api/user-visits.service";
import { useGetUsers } from "@entities/user/api/user.service";
import FilterCombobox from "@features/filters/ui/FilterCombobox";
import FilterText from "@features/filters/ui/FilterText";
import Filters from "@features/filters/ui/Filters";
import { formatDate } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";
import { IUserVisitTableRow } from "@widgets/user-visits/types";

type FiltersType = { search?: string; user?: ISelectElement };

const UserVisitsTable = () => {
  const { t } = useTranslate("main");
  const [filters, setFilters] = useState<FiltersType>({});
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data: visitsData, isLoading: isLoadingVisits } = useGetVisits(
    {
      userId: filters.user?.src,
      search: filters.search,
      size: pagination.pageSize,
      page: pagination.pageIndex
    },
    [pagination, filters]
  );
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const { data: nodeData, isLoading: isLoadingNodes } = useGetMonitorNodes();

  const columnHelper = createColumnHelper<IUserVisitTableRow>();

  const columns = [
    columnHelper.accessor("page", {
      header: t("userVisit.table-page", "Страница")
    }),
    columnHelper.accessor("email", {
      header: t("userVisit.table-email", "E-mail")
    }),
    columnHelper.accessor("name", {
      header: t("userVisit.table-fullname", "ФИО")
    }),
    columnHelper.accessor("spentTime", {
      header: t("userVisit.table-timeSpent", "Времени проведено"),
      cell: info => `${info.getValue() / 1000} сек.`
    }),
    columnHelper.accessor("createdAt", {
      header: t("userVisit.table-dateVisit", "Дата посещения"),
      cell: info => formatDate(info.getValue(), "DD.MM.YYYY HH:mm")
    })
  ];

  const userHashMap = useMemo(() => users?.hashMap("id"), [users]);

  const tableData = useMemo(
    () =>
      visitsData?.visits.map(visit => ({
        ...visit,
        email: userHashMap?.get(visit.userId)?.email,
        name: userHashMap?.get(visit.userId)?.name,
        page: nodeData?.hashMap("id").get(visit.skmTreeNodeId)?.title
      })) ?? [],
    [nodeData, userHashMap, visitsData?.visits]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    rowCount: visitsData?.all,
    state: { pagination },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  });

  const handleChangeFilters = useCallback((v: FiltersType) => {
    setFilters(v);
  }, []);

  if (!visitsData || isLoadingVisits || isLoadingUsers || isLoadingNodes) {
    return <Spinner text="Загрузка" />;
  }

  return (
    <Stack gap={2} height="100%" position="relative">
      <Filters<FiltersType> value={filters} onChange={handleChangeFilters}>
        <Stack direction="row" gap={4}>
          <FilterText label={t("userVisit.search", "Поиск")} labelPlacement="top" name="search" />

          <FilterCombobox
            label={t("userVisit.user", "Пользователь")}
            labelPlacement="top"
            name="user"
            options={users?.list.map(u => ({ src: u.id, label: u.email })) ?? []}
          />
        </Stack>
      </Filters>

      <table style={{ fontSize: 12 }}>
        <thead style={{ position: "sticky", top: -24, backgroundColor: "#fff" }}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableCell borderVariant="rightBottom" key={header.id} sx={{ textAlign: "start", fontWeight: "bold" }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell borderVariant="rightBottom" key={cell.id} sx={{ textAlign: "start" }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <Stack bgcolor="#fff" bottom={0} position="sticky" sx={{ marginTop: "auto", paddingBottom: 2 }}>
        <PaginationFull
          pageSizeProps={{
            pageSize: pagination.pageSize,
            onPageSizeChange: v => setPagination(prev => ({ ...prev, pageSize: v }))
          }}
          paginationProps={{
            page: pagination.pageIndex + 1,
            count: Math.ceil(visitsData.all / pagination.pageSize),
            onChange: (_, page) => table.setPageIndex(page - 1)
          }}
          rowCount={visitsData.all}
        />
      </Stack>
    </Stack>
  );
};

export default UserVisitsTable;
