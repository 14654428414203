import { z } from "zod";

export const configSchema = z
  .object({
    language: z.string(),
    tolgeeApiUrl: z.string().optional(),
    tolgeeKey: z.string().optional(),
    backendFetchUrl: z.string().optional(),
    featureFlags: z.object({
      org: z.string(),
      languageSelector: z.boolean()
    })
  })
  .strict();

export type ConfigType = z.infer<typeof configSchema>;
export const defaultConfig: ConfigType = {
  language: "ru",
  tolgeeApiUrl: "https://i18n.brusnica.tech",
  tolgeeKey: "placeholder",
  backendFetchUrl: undefined,
  featureFlags: { org: "brusnika", languageSelector: false }
};
