import { FC, PropsWithChildren, useMemo, ReactElement } from "react";

import { Responsive } from "@brusnika.tech/ui-kit";
import { Content, Drawer, Module, Header as LayoutHeader } from "@brusnika.tech/ui-portal";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";

import { INode } from "@skm-shared/monitors";

import { useCameraTreeStore } from "@entities/camera/model/camera-tree.store";
import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { useMonitorTreeStore } from "@entities/monitor/model/monitor-tree.store";
import CameraHeader from "@features/camera-header/ui/CameraHeader";
import MonitorHeader from "@features/monitor-header/ui/MonitorHeader";
import MonitorHeaderActions from "@features/monitor-header/ui/MonitorHeaderActions";
import { QueryParams, SidebarType } from "@shared/consts";
import { SidebarTypes } from "@shared/types";
import RightSideBarContent from "@widgets/monitor-comments/ui/RightSidebarContent";

interface Props extends PropsWithChildren {
  sidebar: () => ReactElement;
  sidebarName: SidebarTypes;
}

const Layout = ({ sidebar, sidebarName }: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { data: monitorNodeData } = useGetMonitorNodes();
  const activeMonitorNodeId = useMonitorTreeStore(state => state.activeNode);
  const activeCameraNodeId = useCameraTreeStore(state => state.activeNode);
  const locationCameraNodeId = location.pathname.split("/").at(-1);

  const activeNode: INode | undefined = useMemo(() => {
    const hashMapById = monitorNodeData?.hashMap("id");
    const hashMapByPath = monitorNodeData?.hashMap("path");

    if (hashMapById && hashMapByPath) {
      return (
        hashMapById.get(searchParams.get(QueryParams.nodeId) ?? "") ??
        hashMapByPath.get(location.pathname) ??
        hashMapById.get(activeMonitorNodeId ?? "")
      );
    }
  }, [activeMonitorNodeId, location.pathname, monitorNodeData, searchParams]);

  const headers: Record<SidebarTypes, FC> = useMemo(
    () => ({
      [SidebarType.MAIN]: () => (
        <LayoutHeader
          actions={activeMonitorNodeId ? <MonitorHeaderActions nodeId={activeMonitorNodeId} /> : undefined}
          className="g-layout-header"
          heading={activeMonitorNodeId ? <MonitorHeader nodeId={activeMonitorNodeId} /> : undefined}
        />
      ),
      [SidebarType.ADMIN_MONITOR_TREE]: () => <LayoutHeader className="g-layout-header" />,
      [SidebarType.ADMIN_MAIN]: () => <LayoutHeader className="g-layout-header" />,
      [SidebarType.ADMIN_CAMERA_TREE]: () => <LayoutHeader className="g-layout-header" />,
      [SidebarType.CAMERAS]: () => (
        <LayoutHeader
          className="g-layout-header"
          heading={<CameraHeader nodeId={activeCameraNodeId ?? locationCameraNodeId ?? ""} />}
        />
      )
    }),
    [activeCameraNodeId, activeMonitorNodeId, locationCameraNodeId]
  );
  const CurrentHeader = headers[sidebarName];

  if (searchParams.get(QueryParams.onlyFrame)) {
    return <Outlet />;
  }

  return (
    <Module
      leftSidebar={
        <Drawer resizable>
          <div className="g-left-drawer">{sidebar()}</div>
        </Drawer>
      }
    >
      <Content
        className="g-layout-content"
        header={<CurrentHeader />}
        rightSidebar={<RightSideBarContent node={activeNode} />}
      >
        <Responsive desktop={<Outlet />} mobile={<Outlet />} />
      </Content>
    </Module>
  );
};

export default Layout;
