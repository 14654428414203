import { Divider, Stack } from "@mui/material";
import { toast } from "react-toastify";

import { useGetRoles } from "@entities/role/api/role.service";
import { useAddUser } from "@entities/user/api/user.service";
import { IUserCreateForm } from "@entities/user/types";
import UserCreateForm from "@features/user/ui/UserCreateForm";
import UserTable from "@features/user/ui/UserTable";

const User = () => {
  const { data: roles = [] } = useGetRoles();
  const { mutateAsync: mutateAddUser } = useAddUser();

  const onCreateSubmit = async (form: IUserCreateForm) => {
    try {
      await mutateAddUser({
        email: form.email,
        roleIds: form.roles.map(r => r.src ?? "")
      });
    } catch (e) {
      toast.error("Ошибка создания пользователя");
    }
  };

  return (
    <Stack gap={2}>
      <UserCreateForm roles={roles} onSubmit={onCreateSubmit} />

      <Divider />

      <UserTable />
    </Stack>
  );
};

export default User;
