import { FC } from "react";

import { FormControlLabel, Select } from "@brusnika.tech/ui-kit";
import { useLayout } from "@brusnika.tech/ui-portal";
import { Stack, TextField } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { INode, TreeContentType } from "packages/shared/src/monitors";
import { alphabetical } from "radash";
import { Controller, useFormContext } from "react-hook-form";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { IManageTreeForm } from "@features/manage-tree-form/types";
import { getSelectElement, isOptionEqualToValue } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";

interface Props {
  parentNode?: INode;
  parentOption?: ISelectElement;
}

const FormMainInfo: FC<Props> = ({ parentNode, parentOption }) => {
  const { data: nodeData } = useGetMonitorNodes();
  const { register, control, watch } = useFormContext<IManageTreeForm>();
  const { t } = useTranslate("main");

  const { isDesktop } = useLayout();
  const rowDirection = isDesktop ? "row" : "column";

  const nodeOptions = alphabetical(
    nodeData?.list.map(node => getSelectElement(node.id, node.title)) ?? [],
    item => item.label
  );

  return (
    <>
      <Stack direction={rowDirection}>
        <FormControlLabel
          disabled
          control={<TextField {...register("id")} sx={{ width: 270 }} />}
          label={t("tForm.field-id", "id")}
          labelPlacement="top"
        />

        <div style={{ width: 286 }} />

        {parentNode && (
          <FormControlLabel
            disabled
            control={<TextField sx={{ width: 270 }} {...register("parent.src")} />}
            label={t("tForm.field-parentId", "id родителя")}
            labelPlacement="top"
          />
        )}
      </Stack>

      <Stack direction={rowDirection}>
        <FormControlLabel
          control={<TextField {...register("title")} sx={{ width: 270 }} />}
          label={t("tForm.field-title", "Заголовок")}
          labelPlacement="top"
        />

        <FormControlLabel
          control={<TextField {...register("fullTitle", { required: true })} sx={{ width: 270 }} />}
          label={t("tForm.field-fullTitle", "Полный заголовок")}
          labelPlacement="top"
        />

        <Controller
          control={control}
          defaultValue={parentOption}
          name="parent"
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Select
                  isOptionEqualToValue={isOptionEqualToValue}
                  options={nodeOptions}
                  sx={{ width: 270 }}
                  value={value}
                  onChange={(_, v) => onChange(v)}
                />
              }
              disabled={!parentNode}
              label={t("tForm.field-parent", "Родитель")}
              labelPlacement="top"
            />
          )}
        />
      </Stack>

      <Stack direction={rowDirection}>
        <FormControlLabel
          control={
            <TextField placeholder="/example/url?standalone=2" sx={{ width: 270 }} {...register("externalSrc")} />
          }
          disabled={watch("contentType") === TreeContentType.SKM}
          label={t("tForm.field-supersetLink", "Superset ссылка")}
          labelPlacement="top"
        />

        <FormControlLabel
          control={
            <TextField placeholder="/example/url?standalone=2" sx={{ width: 270 }} {...register("mobileExternalSrc")} />
          }
          disabled={watch("contentType") === TreeContentType.SKM}
          label={t("tForm.field-supersetMobileLink", "Superset ссылка мобильная версия")}
          labelPlacement="top"
        />

        <FormControlLabel
          control={<TextField {...register("path")} sx={{ width: 270 }} />}
          label={t("tForm.field-path", "Путь")}
          labelPlacement="top"
        />
      </Stack>
    </>
  );
};

export default FormMainInfo;
