import { PaddedContentContainer } from "@brusnika.tech/ui-portal";
import { Divider, Stack } from "@mui/material";

import RoleActions from "@widgets/role/ui/RoleActions";
import RoleTable from "@widgets/role/ui/RoleTable";

const RolesPage = () => {
  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <Stack gap={2}>
        <RoleActions />
        <Divider />
        <RoleTable />
      </Stack>
    </PaddedContentContainer>
  );
};

export default RolesPage;
